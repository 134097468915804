import moment from 'moment'
import React from 'react'
import { ON_OBJECT_POST_SAVE } from './constants'
import { Button, notification } from 'antd'

export function formatMomentDate(date, format = 'DD/MM/YYYY') {
  if (date) {
    return moment(date).format(format)
  }
  return undefined
}

export function formatMomentDateTime(date, format = 'DD/MM/YYYY HH:mm') {
  return formatMomentDate(date, format)
}

export function generateRandomString(length) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export function shadeColor(color, percent, alpha = 1) {
  let R = parseInt(color.substring(1, 3), 16)
  let G = parseInt(color.substring(3, 5), 16)
  let B = parseInt(color.substring(5, 7), 16)

  if (R === 0) R = 32
  if (G === 0) G = 32
  if (B === 0) B = 32

  R = parseInt((R * (100 + percent)) / 100)
  G = parseInt((G * (100 + percent)) / 100)
  B = parseInt((B * (100 + percent)) / 100)

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16)
  const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16)
  const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16)

  const colors = hexToRgb('#' + RR + GG + BB)

  return `rgba(${colors.r}, ${colors.g}, ${colors.b}, ${alpha})`
}

export const onWebSocketMessage = (msg, instance, userId, read) => {
  const hideNotification = msg.user_id && msg.user_id === userId

  switch (msg.type) {
    case ON_OBJECT_POST_SAVE:
      if (!hideNotification) {
        const key = `instanceUpdate${Date.now()}`

        notification.info({
          key: key,
          message: 'Object information was updated',
          duration: 5,
          btn: (
            <Button
              type={'primary'}
              onClick={() => {
                read(instance.id)
                notification.close(key)
              }}
            >
              Refresh window
            </Button>
          ),
        })
      }
      break

    default:
      break
  }
}

export const bToMb = (value) => {
  return (value * 0.000008).toFixed(2)
}

export const bToGb = (value) => {
  return (value * 0.000000008).toFixed(2)
}

export const getProvisioningStatusTagColor = (status) => {
  let color
  switch (status) {
    case 'ACTIVE':
      color = 'green'
      break
    case 'PROVISIONING':
      color = 'blue'
      break
    case 'TERMINATED':
      color = 'red'
      break
    default:
      break
  }
  return color
}

export const isValidVoiceBillingPhoneNumber = (value) => {
  return (
    value &&
    (value.toString().length === 10 || value.toString().length === 6) &&
    /^\d+$/.test(value.toString())
  )
}

export const createVoiceBillingPhoneNumberFromInteger = (i, count) => {
  return (parseInt(i) + count - 1).toString().padStart(i.toString().length, '0')
}

export const dmsToDD = (coord) => {
  const parts = coord.split(':')
  if (parts[0].indexOf('-') !== -1) {
    return (
      parseFloat(parts[0]) -
      parseFloat(parts[1]) / 60 -
      parseFloat(parts[2]) / 3600
    )
  } else {
    return (
      parseFloat(parts[0]) +
      parseFloat(parts[1]) / 60 +
      parseFloat(parts[2]) / 3600
    )
  }
}

export const dataURLtoFile = (dataUrl, filename) => {
  let arr = dataUrl.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export const resolveObjectProperty = (path, obj, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

export const stringToColor = (str) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}

export const parseJWT = (token) => {
  const base64Payload = token.split('.')[1]
  const payload = Buffer.from(base64Payload, 'base64')
  return JSON.parse(payload.toString())
}
